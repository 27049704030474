$(function () {
    // Меню категорий на десктопе
    (function () {
        const menuSwitcher = $('.fn_categories_switch')
            , menu = $('.fn_categories_menu')

        menuSwitcher.click(() => menu.toggleClass('show'))

        menu.find('.level-1 > li').hover(function () {
            const li = $(this)

            menu.find('.level-1 > li').removeClass('active')
            li.addClass('active')
        })

        menu.find('.level-2-item-btn').click(function () {
            $(this).toggleClass('active')
            $(this).closest('.level-2-item').find('.level-3-wrapper').slideToggle(300)
        })
    })();
})